<template>
	<div class="contain">
		<div class="breadcrumb">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/diagnosis' }">诊断维修</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/diagnosisExpert' }">{{ num == '1' ? '诊断' : '维修' }}</el-breadcrumb-item>
				<el-breadcrumb-item>{{ InfOjb.name }}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="content">
			<div class="details pt-15">
				<el-row>
					<el-col :span="6">
						<div class="recomoutside">
							<div class="recomBlock mb-20">
								<div class="img"><img :src="photoURL + InfOjb.head" alt="" /></div>
								<div class="Recomword1">{{ InfOjb.name }}</div>
								<div class="Recomword2">诊断专家·{{ InfOjb.city }}</div>
								<div class="rate flex jc-center ai-center darkColor">
									<span>评价：</span>
									<el-rate v-model="InfOjb.score" disabled show-score text-color="#A4A9B0" score-template="{value}" :colors="colors"></el-rate>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="18">
						<div class="up">
							<table class="pl-15 pr-25">
								<tr class="flex">
									<span class="biaoti1">价格：¥</span>
									<span class="blueColor" style="font-size: 30px">{{ typePrice }}</span>
								</tr>
								<tr class="flex jc-between">
									<div class="upLeft flex">
										<span>近1年服务单数：</span>
										<span class="px-10 biaoti1">{{ InfOjb.serviceNum }}单</span>
									</div>
									<div class="upright">
										<!-- <el-button type="primary" icon="el-icon-chat-dot-square"
                      >和我联系</el-button
                    >
                    <el-button type="primary" icon="el-icon-star-off" plain
                      >已点赞</el-button
                    > -->
									</div>
								</tr>
								<tr class="flex">
									<span>服务态度：</span>
									<span class="biaoti1">{{ InfOjb.serviceScore }}</span>
									<span class="px-10">工作态度：</span>
									<span class="biaoti1">{{ InfOjb.workScore }}</span>
									<span class="px-10">完成质量：</span>
									<span class="biaoti1">{{ InfOjb.doneScore }}</span>
								</tr>
							</table>
						</div>
						<!-- 点击分类显示不同的服务信息 -->
						<el-form label-width="90px">
							<el-form-item label="分类：" class="mt-10">
								<span
									@click="chooseType(item, index)"
									:class="{ active: activeIndex == index }"
									class="typeTag cursorPointer"
									v-for="(item, index) in typeList"
									:key="index"
								>
									{{ item.name }}
								</span>
							</el-form-item>
							<el-form-item label="完成时间：">
								<el-date-picker v-model="modifyTime" type="date" @change="selectTime" placeholder="选择日期" :picker-options="pickerOptions">
									>
								</el-date-picker>
							</el-form-item>

							<el-form-item><el-button type="primary" @click="buy">立即购买</el-button></el-form-item>
						</el-form>
					</el-col>
				</el-row>
			</div>
			<div class="goodsInfo">
				<el-tabs type="border-card">
					<el-tab-pane label="专家详情">
						<!-- <img class="w-100" src="./img/details.png" /> -->
						<el-image
							class="my-15 ml-15"
							style="width: 100px; height: 100px"
							:src="item"
							:preview-src-list="imageList"
							v-for="(item, index) in imageList"
							:key="'img' + index"
						></el-image>
						<div class="px-15 word2 mb-30">
							<span class="word1">1.从业资格</span>
							<p>{{ skillObj.qualify }}</p>
							<span class="word1">2、职称</span>
							<p>{{ skillObj.rank }}</p>
							<span class="word1">2、擅长</span>
							<p>{{ skillObj.goodAt }}</p>
							<span class="word1">4、介绍</span>
							<p>{{ skillObj.introduce }}</p>
						</div>
					</el-tab-pane>
					<el-tab-pane label="专家评价" class="ml-15 mt-15 evaluation">暂无评价</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			num: this.$route.query.num,
			activeIndex: 0,
			id: this.$route.query.id,
			infoDetails: {},
			InfOjb: {},
			skillObj: {},
			typePrice: '',
			typeList: [],
			typeId: '',
			imageList: [],
			modifyTime: '',
			colors: ['#FF9F0A', '#FF9F0A', '#FF9F0A'],
			value1: '',
			infoObj: null,
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 8.64e7;
				}
			}
		};
	},
	methods: {
		//   获取个人信息
		personalInfo() {
			this.$get('/shop-api/mall/expert/detail', {
				id: this.id
			}).then(data => {
				if (data.code == 1000) {
					this.infoObj = data.data;
					this.InfOjb = data.data.expertEo; //专家信息
					this.skillObj = data.data.skill; //诊断
					this.imageList = data.data.skill.attachmentList.map(i => this.photoURL + i); // 预览图片
					this.typePrice = data.data.serviceList[this.activeIndex].price; //价格
					this.typeList = data.data.serviceList;
					this.typeId = data.data.serviceList[0].id;
				} else if (data.code === 1003) {
					this.$message('请登录！');
				}
			});
		},
		// 选择日期
		selectTime(val) {
			this.modifyTime = this.formatDate(val);
		},
		// 处理时间格式
		formatTen(num) {
			return num > 9 ? num + '' : '0' + num;
		},
		formatDate(date) {
			var date = new Date(date);
			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			var day = date.getDate();
			var hour = date.getHours();
			var minute = date.getMinutes();
			var second = date.getSeconds();
			return year + '-' + this.formatTen(month) + '-' + this.formatTen(day) + ' ' + this.formatTen(hour) + ':' + this.formatTen(minute) + ':' + this.formatTen(second);
		},
		// 选择分类
		chooseType(item, index) {
			this.activeIndex = index;
			this.typeId = item.id;
			this.params = item[index];
			this.typePrice = item.price;
		},
		// 购买时带参类型ID跟时间
		buy() {
			if (this.modifyTime) {
				var params = {
					modifyTime: this.modifyTime,
					typeId: this.typeId,
					...this.infoObj
				};
				this.$router.push(`/diagnosisOrder?searchInfo=${JSON.stringify(params)}`);
			} else {
				this.$message.error('请选择时间');
			}
		},
	},
	mounted() {
		this.personalInfo();
	}
};
</script>

<style lang="scss" scoped>
* {
	.contain {
		background-color: #f0f2f5;
		padding-bottom: 30px;

		.breadcrumb {
			letter-spacing: 1px;
			width: 1440px;
			height: 40px;
			color: #8c8c8c;
			background-color: #efeff4;
			margin: 0 auto;

			.el-breadcrumb {
				display: flex;
				align-items: center;
				height: 40px;
			}
		}

		.content {
			width: 1440px;
			margin: 15px auto;
			background-color: #f0f2f5;

			.details {
				background-color: #fffffd;

				.recomoutside {
					display: flex;
					justify-content: center;
					align-items: center;

					.recomBlock {
						margin: 0 auto;
						width: 340px;
						text-align: center;

						.img {
							width: 300px;
							height: 300px;

							img {
								border-radius: 5%;
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
						}

						.Recomword1 {
							font-weight: 600;
							color: #262626;
							font-size: 20px;
							margin: 12px 0;
						}

						.Recomword2 {
							color: #888888;
							font-size: 16px;
							margin: 12px 0;
						}

						.rate {
							margin: 20px 0;
						}
					}
				}

				.up {
					table {
						padding: 25px 15px;
						width: 97%;
						background-color: #f4f9ff;
						border-radius: 8px;

						tr {
							height: 40px;
							line-height: 40px;
						}
					}
				}

				.typeTag {
					font-weight: 400;
					font-size: 16px;
					color: #262626;
					padding: 8px;
					margin-right: 10px;
					line-height: 40px;
					border-radius: 4px;
					background-color: #f5f5f5;
				}

				.el-button {
					width: 200px;
					height: 60px;
					background-color: #4a99f6;
					border-radius: 8px;
				}
			}
		}

		.goodsInfo {
			letter-spacing: 1px;

			.title {
				height: 60px;
				line-height: 60px;
				background-color: #f5f5f5;
			}

			.word1 {
				color: #262626;
				font-size: 16px;
				font-weight: bold;
				padding-top: 15px;
			}

			.word2 {
				span {
					padding-top: 10px;
				}

				p {
					color: #595959;
					padding: 10px 0;
				}
			}
		}
	}
}

.evaluation {
	height: 400px;
	background-color: white;
}

.active {
	border-radius: 4px;
	border: 1px solid #4a99f6;
	background-color: #f4f9ff !important;
	color: #4a99f6 !important;
}

// 组件里的样式
::v-deep .el-tabs--border-card > .el-tabs__content {
	padding: 0;
}

::v-deep .el-tabs--border-card > .el-tabs__header {
	height: 54px;
}

::v-deep .el-button {
	width: 134px;
}
</style>
